<template>
  <div class="about">
    <h1>Logout</h1>
  </div>
</template>


<script>

  import store from '../store/index';

  export default {
    mounted: function() {

      store.commit('deconnexion');
      this.$router.push({name: 'Home'}).catch(() => {});

    }
    
  }

</script>
