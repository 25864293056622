<template>
  <div>

    <b-container fluid>

      <b-table small
        hover 
        :items="documents" 
        :fields="filtreColonnes"
        :filter="filter"
        :per-page="parPage"
        :current-page="pageCourante"
        show-empty
        ref="tableauDocuments"
        empty-filtered-text="Aucun document trouvées avec le filtre."
        empty-text=""
        :selectable="isSelectable"
        @filtered="onFiltered"
        @row-selected="onRowSelected"
        :busy="isBusy"
        sticky-header="590px"
        :tbody-tr-class="rowClass"
      >

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(Destination)="row">
          {{row.item.TIERS_DEST + ' (' + row.item.VILLE_DEST + ')'}}
        </template>

        <template #cell(boutonTelechargement)="row">
          <b-button squared class="bouton-telecharge" @click="telechargerLeDocument(row.item, row.index, $event.target)" :disabled=isBoutonTelechargementDisabled>

            <font-awesome-icon icon="download" />

            <template v-if="row.item.TELECHARGE == 1">

              <font-awesome-layers class="logo-check-stack fa-lg">
                <font-awesome-icon text-white icon="circle" class="logo-check-cercle"  />
                <font-awesome-icon icon="check" class="logo-check-check" transform="shrink-6" />
              </font-awesome-layers>

            </template>

          </b-button>

        </template>

      </b-table>

      <b-pagination
        id="Pagination"
        v-model="pageCourante"
        :total-rows="lignesTotales"
        :per-page="parPage"
        align="fill"
      ></b-pagination>

    </b-container>

  </div>
</template>

<script>

  import url from '../router/url';
  const axios = require('axios');
  import moment from 'moment';
  
  export default {
    props: {
      'filter': String,
      'parPage': Number,
      'isSelectable' : Boolean,
      'numeroOT' : Number,
      'numeroFacture' : String,
      'dateDeDebut' : String,
      'dateDeFin' : String,
      'refClient' : String,
      'ShowDownloaded': Boolean
    },
    data() {
      return {
        pageCourante: 1,
        lignesTotales : 1,
        selected: false,
       // ShowDownloaded: true,
        isBusy : false,
        listeDocumentsCoche : [],
        documents: [],
        colonnes: [
          {
            label: 'Ref. Interne',
            key: 'LOT',
            isVisible: true,
            sortable: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Facture',
            key: 'FACT_ID_COMPTA',
            isVisible: true,
            sortable: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Ref. Client',
            key: 'LOT_REFCLIENT',
            isVisible: true,
            sortable: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Tiers',
            key: 'TIERS_NOMSOCIETE',
            isVisible: true,
            sortable: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Destination',
            key: 'Destination',
            isVisible: true,
            sortable: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Type',
            key: 'LIBELLE_TYPE',
            isVisible: true,
            sortable: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Date livraison',
            key: 'LOT_DATHEURLIVR',
            isVisible: true,
            sortable: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: '',
            key: 'boutonTelechargement',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
        ],
      }
    },
    mounted() {
      this.lignesTotales = this.documents.length;
    },
    methods: {
      rowClass(item, type) {
        if (!item || type !== 'row') return ''
        if (item.TELECHARGE == 1 && !this.ShowDownloaded) return 'd-none'
      },      
      getDocumentsParOT() {

        this.$refs.tableauDocuments.clearSelected();

        this.isBusy = true;

        var user_id = this.$store.state.user.id;
        var ot_id = this.numeroOT;

        axios.get(url.GetDocumentsParOT + user_id + '/' + ot_id ).then(response => {

          this.documents = [];
          this.documents = response.data;
          this.lignesTotales = this.documents.length;
          this.isBusy = false;
          this.retourneLeNombreDeDocuments();
        
        }).catch(function (error) {
          alert('Erreur lors de la récupération des documents. ' + error);
        });

      },
      getDocumentsParFacture() {

        this.$refs.tableauDocuments.clearSelected();

        this.isBusy = true;

        var user_id = this.$store.state.user.id;
        var facture_id = this.numeroFacture;

        axios.get(url.GetDocumentsParFacture + user_id + '/' + facture_id ).then(response => {

          this.documents = [];
          this.documents = response.data;
          this.lignesTotales = this.documents.length;
          this.isBusy = false;
          this.retourneLeNombreDeDocuments();
        
        }).catch(function (error) {
          alert('Erreur lors de la récupération des documents. ' + error);
        });

      },
      getDocumentsParRefClient() {

        this.$refs.tableauDocuments.clearSelected();

        this.isBusy = true;

        var user_id = this.$store.state.user.id;
        var ref_client = this.refClient;

        axios.get(url.GetDocumentsParRefClient + user_id + '/' + encodeURIComponent(ref_client) ).then(response => {

          this.documents = [];
          this.documents = response.data;
          this.lignesTotales = this.documents.length;
          this.isBusy = false;
          this.retourneLeNombreDeDocuments();

        }).catch(function (error) {
          alert('Erreur lors de la récupération des documents. ' + error);
        });

      },
      getDocumentsParDate() {

        this.$refs.tableauDocuments.clearSelected();

        this.isBusy = true;

        var user_id = this.$store.state.user.id;
        var dateDebut = moment(this.dateDeDebut).format('DDMMYYYY');
        var dateFin = moment(this.dateDeFin).format('DDMMYYYY');

          axios.get(url.GetDocumentsParDate + user_id + '/' + dateDebut + '/' + dateFin ).then(response => {

            this.documents = [];
            this.documents = response.data;
            this.lignesTotales = this.documents.length;
            this.isBusy = false;
            this.retourneLeNombreDeDocuments();
          
          }).catch(function (error) {

            alert('Erreur lors de la récupération des documents. ' + error);

          });

      },
      telechargerLesDocuments() {

        if (this.listeDocumentsCoche.length > 1) {

          this.$emit('telechargementEnCours', true);

          var user_id = this.$store.state.user.id;

          axios({
            url: url.TelechargerPlusieursDocuments,
              params:{'idDocuments' : this.listeDocumentsCoche, 'idUtilisateur' : user_id},
              method: 'GET',
              responseType: 'blob',
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'documents.zip');
              document.body.appendChild(link);
              link.click();
              this.metAJourLesIconesDuTableauPourHistorique();
              this.$emit('telechargementEnCours', false);
          }).catch(function (error) {
            alert('Erreur lors du processus du téléchargement. ' + error);
            this.$emit('telechargementEnCours', false);
          });

        } 
      },
      telechargerLeDocument(rowItem) {
        rowItem.TELECHARGE = 1;
        var user_id = this.$store.state.user.id;
        window.open(url.TelechargeLeDocument + rowItem.CLE + '/' + user_id, '_blank');
      },
      onFiltered(filteredItems) {
        this.lignesTotales = filteredItems.length;
        this.pageCourante = 1;
      },
      selectionnerToutesLesLignes() {

        if (this.selected) {
          this.selected = false;
          this.$refs.tableauDocuments.clearSelected();
        } else {
          this.selected = true;
          this.$refs.tableauDocuments.selectAllRows();
        }

      },
      selectionnerNonTelecharge() {

        this.ShowDownloaded = !this.ShowDownloaded;

      },      
      onRowSelected(item) {
                    
        this.listeDocumentsCoche = [];

        for (let i = 0; i < item.length; i++) {
          this.listeDocumentsCoche.push(item[i].CLE);
        }

        if (this.listeDocumentsCoche.length == 0) {
          this.selected = false;
        }
        
        this.retourneLeNombreDeDocumentsCoche();

      },
      retourneLeNombreDeDocuments() {
        this.$emit('nombreDeDocuments', this.lignesTotales);
      },
      retourneLeNombreDeDocumentsCoche() {
        this.$emit('nombreDeDocumentsCoche', this.listeDocumentsCoche.length);
      },
      metAJourLesIconesDuTableauPourHistorique() {
        //Evite de rafraichir le tableau, on va directement modifier les icones dans les résultats

        for (let i = 0; i < this.documents.length; i++) {
          
          for (let k = 0; k < this.listeDocumentsCoche.length; k++) {

            if (this.listeDocumentsCoche[k] == this.documents[i].CLE) {
              this.documents[i].TELECHARGE = 1;
            }
            
          }
          
        }

      },
    },
    computed: {
      filtreColonnes() {
        return this.colonnes.filter(field => field.isVisible);
      },
      isBoutonTelechargementDisabled() {
        return this.listeDocumentsCoche.length > 1;
      },
    },
  }

</script>

<style>

  /* Ensemble des 2 logos : cercle et check */
  .logo-check-stack {
    position:absolute;
    top:-5px;
    right: -8px;
  }

  .logo-check-cercle {
    color: #499a48;
  }
  .logo-check-check {
    color: white;
  }

  .bouton-telecharge {
    position: relative;
  }

</style>