let api = "/api/";                  //prod
//let api = "http://172.24.0.2:8080/";     //dev

let CheckSiUtilisateurExiste = api + "utilisateurValide/";
let GetInformationsUtilisateur = api + "utilisateurRead/";
let GetTousLesUtilisateurs = api + "utilisateurs";
let CreationCompte = api + "utilisateurCreate";
let UpdateCompte = api + "utilisateurUpdate";
let SuppressionCompte = api + "utilisateurDelete/";
let GetTypesDocGed = api + "typesDoc";
let GetMappageTypesDocuments = api + "typesDocMappageRead/";
let CreationMappageTypesDocuments = api + "typesDocMappageCreate";
let SupprimeMappageTypesDocuments = api + "typesDocMappageDelete";
let GetTiers = api + "tiers/";
let CreationMappageTiers = api + "tiersMappageCreate";
let GetMappageTiers = api + "tiersMappageRead/";
let SupprimeMappageTiers = api + "tiersMappageDelete";
let GetTiersExp = api + "tiersExp/";
let CreationMappageTiersExp = api + "tiersExpMappageCreate";
let GetMappageTiersExp = api + "tiersExpMappageRead/";
let SupprimeMappageTiersExp = api + "tiersExpMappageDelete";
let GetDocumentsParOT = api + "documentsByOt/";
let GetDocumentsParDate = api + "documentsByDates/";
let TelechargeLeDocument = api + "documentsDownload/"
let TelechargerPlusieursDocuments = api + "documentsMultiZip";
let GetDocumentsParFacture = api + "documentsByFacture/";
let ChangePasswordFirstConnexion = api + "utilisateurPasswordFirstConnexion";
let GetDocumentsParRefClient = api + "documentsByRefClient/";

export default {
    CheckSiUtilisateurExiste,
    GetInformationsUtilisateur,
    GetTousLesUtilisateurs,
    CreationCompte,
    UpdateCompte,
    SuppressionCompte,
    GetTypesDocGed,
    GetMappageTypesDocuments,
    CreationMappageTypesDocuments,
    SupprimeMappageTypesDocuments,
    GetTiers,
    CreationMappageTiers,
    GetMappageTiers,
    SupprimeMappageTiers,
    GetTiersExp,
    CreationMappageTiersExp,
    GetMappageTiersExp,
    SupprimeMappageTiersExp,
    GetDocumentsParOT, 
    GetDocumentsParDate, 
    TelechargeLeDocument,
    TelechargerPlusieursDocuments,
    GetDocumentsParFacture,
    ChangePasswordFirstConnexion,
    GetDocumentsParRefClient
}