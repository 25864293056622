<template>
  <div>

      <b-form-group
          id="input-group-1"
          :label=label
          label-for="input-motDePasse"
        >

          <VuePassword
            v-model="nouveauMotDePasse"
            id="input-motDePasse"
            disableStrength
            type="password"
            @input="checkPassword" 
            autocomplete="off" 
          />

          <div class="complexiteMotDePasse">
            <ul>
              <li v-bind:class="{ is_valid: contientHuitCaracteres }">8 caractères minimum</li>
              <li v-bind:class="{ is_valid: contientUnCaractereSpecial }">Au moins un caractère spécial</li>
              <li v-bind:class="{ is_valid: contientAuMoinsUnChiffre }">Au moins un chiffre</li>
            </ul>
          </div>


        </b-form-group>

  </div>
</template>

<script>
import VuePassword from 'vue-password';

  export default {
    components: {
        VuePassword,
    },
    props: {
        label: {
          default: ''
        }
    },
    data() {
      return {
        nouveauMotDePasse : '',
        contientHuitCaracteres : false,
        contientAuMoinsUnChiffre : false,
        contientUnCaractereSpecial : false,
        motDePasseValide : false,
      }
    },
    methods: {
      checkPassword() {
          
          const format = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
          
          if (this.nouveauMotDePasse.length > 8) {
            this.contientHuitCaracteres = true;
          } else {
            this.contientHuitCaracteres = false;
          }
          
          this.contientAuMoinsUnChiffre = /\d/.test(this.nouveauMotDePasse);
          this.contientUnCaractereSpecial = format.test(this.nouveauMotDePasse);

          this.$emit('motDePasse', this.nouveauMotDePasse);
          
          if (this.contientHuitCaracteres === true &&
              this.contientUnCaractereSpecial === true &&
              this.contientAuMoinsUnChiffre === true) {
                this.motDePasseValide = true;
                this.$emit('motDePasse', this.nouveauMotDePasse);
                this.$emit('motDePasseValide', this.motDePasseValide);
          } else {
              this.motDePasseValide = false;
              this.$emit('motDePasseValide', this.motDePasseValide);
          }

        },
    },

  }

</script>
