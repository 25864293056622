<template>
  <div>

    <b-container fluid>
      <b-row>
        <b-col cols="4">
          <!--Liste des comptes-->
          <b-row>
            <b-col>
              <p>Séléctionnez un compte</p>  
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <TableauDesComptes :isSelectable=true @ligneID="getCompteID" @compteSelectionne="siCompteSelected"></TableauDesComptes>
            </b-col>  
          </b-row>
        </b-col>

        <b-col cols="8">
          <!--Liste des clients mappés-->
          <b-row>
            <b-col>
              <p>
                Liste des clients associés au compte  
                <span class="text-right bouton-ajouter-tiers"> 
                  <b-button squared @click="afficherModalAjouterUnTiersAuCompte" :disabled=!disabledBouttonAjouterTiers variant="secondary">
                    <font-awesome-icon icon="plus" /> Ajouter un client</b-button>
                </span>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <template v-if="disabledBouttonAjouterTiers">
                <TableauDesTiers 
                  :compteID=compteIDSelectionnee 
                  ref="refTableauTiers" 
                ></TableauDesTiers>
              </template>
            </b-col>
          </b-row>

          <!--Liste des expéditeurs mappés-->
          <b-row>
            <b-col>
              <p>
                Liste des expéditeurs associés au compte  
                <span class="text-right bouton-ajouter-tiers"> 
                  <b-button squared @click="afficherModalAjouterUnTiersExpAuCompte" :disabled=!disabledBouttonAjouterTiers variant="secondary">
                    <font-awesome-icon icon="plus" /> Ajouter un expéditeur</b-button>
                </span>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <template v-if="disabledBouttonAjouterTiers">
                <TableauDesTiersExp 
                  :compteID=compteIDSelectionnee 
                  ref="refTableauTiersExp" 
                ></TableauDesTiersExp>
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-row>          


      <b-modal id="modalAjouterTiersAuCompte" title="Ajouter un client" ref="modalTiers" scrollable>

        <template v-if="messageErreurCreationCompte">
          <p><b-alert show variant="danger"><span v-html="messageErreurCreationCompte"></span></b-alert></p>
        </template>
        <template v-if="messageCreationCompteSuccess">
          <p><b-alert show variant="success"><span v-html="messageCreationCompteSuccess"></span></b-alert></p>
        </template>

        <CriteresDeRecherche @filtre="setFiltre" @parPage="setParPage" :activeLimiteCaractere="true"></CriteresDeRecherche>

        <TableauSelectionDuTiersPourLeMappage 
          :filter=filter
          :parPage=parPage
          :typeTiers="strTypeClient"
          @GetTiersPourInsertion="getTiersSelected"
        ></TableauSelectionDuTiersPourLeMappage>
         
        <template #modal-footer="{cancel}">

          <b-button squared @click="cancel()">
            Fermer
          </b-button>
          <b-button squared variant="success" @click="validationAjouterNouveauTiers()">
            Ajouter
          </b-button>

        </template>

      </b-modal>      

      <b-modal id="modalAjouterTiersExpAuCompte" title="Ajouter un expéditeur" ref="modalTiersExp" scrollable>

        <template v-if="messageErreurCreationCompte">
          <p><b-alert show variant="danger"><span v-html="messageErreurCreationCompte"></span></b-alert></p>
        </template>
        <template v-if="messageCreationCompteSuccess">
          <p><b-alert show variant="success"><span v-html="messageCreationCompteSuccess"></span></b-alert></p>
        </template>

        <CriteresDeRecherche @filtre="setFiltre" @parPage="setParPage" :activeLimiteCaractere="true"></CriteresDeRecherche>

        <TableauSelectionDuTiersPourLeMappage 
          :filter=filter
          :parPage=parPage          
          :typeTiers="strTypeExp"          
          @GetTiersPourInsertion="getTiersSelected"
        ></TableauSelectionDuTiersPourLeMappage>
         
        <template #modal-footer="{cancel}">

          <b-button squared @click="cancel()">
            Fermer
          </b-button>
          <b-button squared variant="success" @click="validationAjouterNouveauTiersExp()">
            Ajouter
          </b-button>

        </template>

      </b-modal>

    </b-container>

  </div>
</template>


<script>
  import TableauDesComptes from '../../components/TableauDesComptes.vue';
  import TableauDesTiers from '../../components/TableauDesTiers.vue';
  import TableauDesTiersExp from '../../components/TableauDesTiersExp.vue';
  import TableauSelectionDuTiersPourLeMappage from '../../components/TableauSelectionDuTiersPourLeMappage.vue';
  import CriteresDeRecherche from '../../components/CriteresDeRechercheTableau.vue';
  import url from '../../router/url.js';
  const axios = require('axios');

  export default {
    components: {
      TableauDesComptes,
      TableauDesTiers,
      TableauDesTiersExp,
      TableauSelectionDuTiersPourLeMappage,
      CriteresDeRecherche,
    },
    data() {
        return {
            compteIDSelectionnee: null,
            tiersID : '',
            strTypeClient: "CLIENT",
            strTypeExp: "EXP",
            filter:'',
            parPage:20,
            ListeDesTiersAInserer : [],
            messageErreurCreationCompte : '',
            messageCreationCompteSuccess : '',
            disabledBouttonAjouterTiers : false,
        }
    },
    methods: {
      getTiersSelected(value) {
        this.ListeDesTiersAInserer = value;
      },
      getCompteID(value) {
        this.compteIDSelectionnee = value;
      },
      siCompteSelected(value) {
        this.disabledBouttonAjouterTiers = value;
      },
      afficherModalAjouterUnTiersAuCompte() {
        this.$bvModal.show('modalAjouterTiersAuCompte');        
        this.messageErreurCreationCompte = '';
        this.messageCreationCompteSuccess = '';
      },
      afficherModalAjouterUnTiersExpAuCompte() {
        this.$bvModal.show('modalAjouterTiersExpAuCompte');        
        this.messageErreurCreationCompte = '';
        this.messageCreationCompteSuccess = '';
      },
      validationAjouterNouveauTiers() {

        if (this.ListeDesTiersAInserer.length > 0) {

          this.messageErreurCreationCompte = '';
          this.messageCreationCompteSuccess = '';

          for (let i = 0; i < this.ListeDesTiersAInserer.length; i++) {
            
            var formData = new FormData();
            formData.append('idUtilisateur', this.compteIDSelectionnee);
            formData.append('idTiers', this.ListeDesTiersAInserer[i].TIERS_ID);
  
            axios.post(url.CreationMappageTiers, formData).then(response => {
  
              var mappageSuccess = response.data == "1";
              var mappageExistant = response.data == "0";
              var utilisateurInexistant = response.data == "-1";
              var tiersInexistantOuNonActif = response.data == "-2";
              var erreurNonDefinie = response.data == "-3";
  
              if (mappageExistant) {
                this.messageErreurCreationCompte += "Le mappage " + this.ListeDesTiersAInserer[i].TIERS_ID + ' existe déjà. <br>';
              } else if (utilisateurInexistant) {
                this.messageErreurCreationCompte += "Erreur lors de la création du compte pour l'ID " + this.ListeDesTiersAInserer[i].TIERS_ID + '. <br>';
              } else if (tiersInexistantOuNonActif) {
                this.messageErreurCreationCompte += "Tiers "+  + this.ListeDesTiersAInserer[i].TIERS_ID +" inexistant.";
              } else if (erreurNonDefinie) {
                this.messageErreurCreationCompte += "Erreur inconnue pour l'ID " + this.ListeDesTiersAInserer[i].TIERS_ID + '. <br>';
              } else if (mappageSuccess) {
                this.messageCreationCompteSuccess += "Mappage " + this.ListeDesTiersAInserer[i].TIERS_ID + " ajouté avec succès. <br>";
                //On ajoute les data directement dans le tableau pour éviter de lancer une requete de rafraichissement.
                this.$refs.refTableauTiers.listeTiers.push({ID_TIERS: this.ListeDesTiersAInserer[i].TIERS_ID, TIERS_NOMSOCIETE: this.ListeDesTiersAInserer[i].TIERS_NOMSOCIETE });                
              }
            
            })

          }

        }
      },
      validationAjouterNouveauTiersExp() {

        if (this.ListeDesTiersAInserer.length > 0) {

          this.messageErreurCreationCompte = '';
          this.messageCreationCompteSuccess = '';

          for (let i = 0; i < this.ListeDesTiersAInserer.length; i++) {
            
            var formData = new FormData();
            formData.append('idUtilisateur', this.compteIDSelectionnee);
            formData.append('idTiers', this.ListeDesTiersAInserer[i].TIERS_ID);
  
            axios.post(url.CreationMappageTiersExp, formData).then(response => {
  
              var mappageSuccess = response.data == "1";
              var mappageExistant = response.data == "0";
              var utilisateurInexistant = response.data == "-1";
              var tiersInexistantOuNonActif = response.data == "-2";
              var erreurNonDefinie = response.data == "-3";
  
              if (mappageExistant) {
                this.messageErreurCreationCompte += "Le mappage " + this.ListeDesTiersAInserer[i].TIERS_ID + ' existe déjà. <br>';
              } else if (utilisateurInexistant) {
                this.messageErreurCreationCompte += "Erreur lors de la création du compte pour l'ID " + this.ListeDesTiersAInserer[i].TIERS_ID + '. <br>';
              } else if (tiersInexistantOuNonActif) {
                this.messageErreurCreationCompte += "Tiers "+  + this.ListeDesTiersAInserer[i].TIERS_ID +" inexistant.";
              } else if (erreurNonDefinie) {
                this.messageErreurCreationCompte += "Erreur inconnue pour l'ID " + this.ListeDesTiersAInserer[i].TIERS_ID + '. <br>';
              } else if (mappageSuccess) {
                this.messageCreationCompteSuccess += "Mappage " + this.ListeDesTiersAInserer[i].TIERS_ID + " ajouté avec succès. <br>";
                //On ajoute les data directement dans le tableau pour éviter de lancer une requete de rafraichissement.                
                this.$refs.refTableauTiersExp.listeTiers.push({ID_TIERS: this.ListeDesTiersAInserer[i].TIERS_ID, TIERS_NOMSOCIETE: this.ListeDesTiersAInserer[i].TIERS_NOMSOCIETE });
              }
            
            })

          }

        }
      },
      setFiltre(value) {
        this.filter = value;
      },
      setParPage(value) {
        this.parPage = value;
      },
    },

  }

</script>

<style> 

  .bouton-ajouter-tiers {
    float:right;
    margin-top: -5px;
  }

</style>