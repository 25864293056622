import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage, //On précise l'utilisation du stockage session au lieu de local
  })],
  state: {
    isLogged: false,
    user: {
      id : null,
      name : null,
      isAdmin: false,
    },
  },
  mutations: {
    deconnexion(state) {
      state.isLogged = false;
      state.user.isAdmin = false;
      state.user.id = null;
      state.user.name = null;
    },
    utilisateurEnCours(state, payload) {
      state.user.id = payload.id;
      state.user.name = payload.name;
      state.user.isAdmin = payload.isAdmin;
      state.isLogged = true;
    }
  },
  actions: {
    connexion(context, payload) {
      context.commit('utilisateurEnCours', payload);
    },
    deconnexion(context) {
      context.commit('deconnexion');
    }
  },
  modules: {
  }
})
