<template>
  <div>

    <b-input-group>
      <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Rechercher"
        :state="limiteCaractere"></b-form-input>

      <b-input-group-append>
        <b-button squared :disabled="!filter" @click="filter = ''">Effacer</b-button>
      </b-input-group-append>

      <b-form-invalid-feedback id="input-live-feedback">
        Entrez au moins 3 caractères
      </b-form-invalid-feedback>

    </b-input-group>


    <template v-if="activeNombreDeResultatParPage">
      <b-card header="Résultats par paaaage">

        <b-card-text>

          <b-form-group label-for="filter-input">

            <b-form-group label-for="per-page-select">

              <b-form-select id="per-page-select" v-model="parPage" :options="pageOptions"></b-form-select>

            </b-form-group>

          </b-form-group>

        </b-card-text>
      </b-card>

    </template>

  </div>
  
</template>

<script>
export default {
  props: {
    activeLimiteCaractere: {
      default: false,
    },
    activeNombreDeResultatParPage: {
      default: true,
    },
  },
  data() {
    return {
      filter: '',
      parPage: 20,
      pageOptions: [20, 50, 100],
      pageCourante: 1,
      lignesTotales: 1,
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.lignesTotales = filteredItems.length;
      this.pageCourante = 1;
    },
  },
  computed: {
    limiteCaractere() {
      if (this.activeLimiteCaractere) {
        return this.filter.length > 2 ? true : false;
      } else {
        return null;
      }
    },
  },
  watch: {
    filter: function (val) {
      this.$emit('filtre', val);
    },
    parPage: function (val) {
      if (this.activeNombreDeResultatParPage) {
        this.$emit('parPage', val);
      }
    }
  }
}

</script>
