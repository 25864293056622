<template>
  <div>
    <b-container>
      <b-row class="justify-content-md-center">
        <b-col cols="4">

          <img src="../assets/images/logo-tvm.png" class="logo-centre" alt="Logo TVM" >

          <b-form @submit="connexion">
            <b-form-group
              id="input-group-1"
              label="Nom d'utilisateur"
              label-for="input-nom-utilisateur"
            >
              <b-form-input
                id="input-nom-utilisateur"
                v-model="form.nomUtilisateur"
                type="text"
                autocomplete="off"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group 
              id="input-group-2" 
              label=" Mot de passe" 
              label-for="input-mot-de-passe">
              <VuePassword
                  v-model="form.motDePasse"
                  id="input-mot-de-passe"
                  disableStrength
                  type="password"
                  required
                  />
            </b-form-group>

            <b-button squared type="submit" variant="secondary" class="btn-block">Se connecter</b-button>
          </b-form>

          <br>

          <template v-if="erreurIdentification">
            <p>
              <b-alert show variant="warning">{{ erreurIdentification }}</b-alert>
            </p>
          </template>

          <template v-if="successMessage">
            <p>
              <b-alert show variant="success">{{ successMessage }}</b-alert>
            </p>
          </template>

          <b-modal title="Première connexion" id="modalPremiereConnexionChangementDeMotDePasse">
            <p>Vous devez modifier votre mot de passe lors de votre première connexion.</p>
            <inputChangerMotDePasse @motDePasse="setMotDePasse" @motDePasseValide="setMotDePasseValide" />

            <template #modal-footer>

              <b-button squared variant="success" :disabled=!nouveauMotDePasseValide @click="modifierMotDePasse()">
                Confirmer
              </b-button>

            </template>
          </b-modal>

          

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>

  import Vue from 'vue';
  import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
  import url from '../router/url.js';
  import VuePassword from 'vue-password';
  import store from '../store/index';
  import inputChangerMotDePasse from '../components/InputChangerMotDePasse.vue';
  const axios = require('axios');

  Vue.use(BootstrapVue);
  Vue.use(IconsPlugin);

  export default {
    components: {
      VuePassword,
      inputChangerMotDePasse,
    },
    data() {
      return {
        form: {
          nomUtilisateur : '',
          motDePasse : '',
        },
        erreurIdentification: '',
        successMessage : '',
        motDePassePremiereConnexion : '',
        nouveauMotDePasseValide : false,
        utilisateurEnCours : '',
      }
    },
    methods: {
      connexion(event) {
        event.preventDefault();

        this.successMessage = '';
        this.erreurIdentification = '';

        var route = this.$router;
        var variablesLocales = this;

        axios.get(url.CheckSiUtilisateurExiste + this.form.nomUtilisateur + '/' + this.form.motDePasse)
        .then(function (response) {

          if (response.data.idUtilisateur) {

            var idUtilisateur = response.data.idUtilisateur;
            var nomUtilisateur = variablesLocales.form.nomUtilisateur;

            if (idUtilisateur > 0) {

              variablesLocales.utilisateurEnCours = idUtilisateur;

              axios.get(url.GetInformationsUtilisateur + idUtilisateur)
              .then(function (response) {

                if (response.data[0].MOT_DE_PASSE == response.data[0].MOT_DE_PASSE_INITIAL) {
                  variablesLocales.$bvModal.show('modalPremiereConnexionChangementDeMotDePasse');
                  return;
                }

                if (response.data[0].ADMIN === 'O') {
                  store.dispatch('connexion', {id:idUtilisateur, name:nomUtilisateur, isAdmin:true });
                } else {
                  store.dispatch('connexion', {id:idUtilisateur, name:nomUtilisateur, isAdmin:false });
                }
              
                route.push("/").catch(() => {});
  
              })
              .catch(function (error) {
                console.log(error);
              });

            } else {
              variablesLocales.erreurIdentification = 'Le compte ou le mot de passe que vous avez entré n\'est pas valide.'
              store.dispatch('deconnexion');
            }


          } else {
            store.dispatch('deconnexion');
          }

        })
        .catch(function (error) {
          console.log(error);
        });


      },
      setMotDePasse(valeur) {
        this.motDePassePremiereConnexion = valeur;
      },
      setMotDePasseValide(valeur) {
        this.nouveauMotDePasseValide = valeur;
      },
      modifierMotDePasse() {

        let data = { 
          id: this.utilisateurEnCours, 
          libelle: this.form.nomUtilisateur, 
          motDePasse: this.motDePassePremiereConnexion 
        };

        axios.put(url.ChangePasswordFirstConnexion, data).then(response => {

          var isUpdated = response.data == "1";

          if (isUpdated) {
            this.successMessage = "Mot de passe correctement mis à jour. Vous pouvez vous connecter avec le nouveau mot de passe.";
            this.messageErreurMiseAJourDuCompte = '';
            this.form.motDePasse = '';
          } else {
            this.successMessage = '';
            this.messageErreurMiseAJourDuCompte = 'Erreur lors de la mise à jour du compte.';
          }

          this.$bvModal.hide('modalPremiereConnexionChangementDeMotDePasse');
        
        }).catch(function (error) {
          alert('Erreur lors de la mise à jour du compte. ' + error);
        });
      }
    }
    
  }

</script>