<template>
  <div>

    <b-container fluid>

      <b-row>

        <b-col cols="3">

          <b-card
            header="Recherche"
          >
          
            <b-card-text>

              <b-input-group>
                <b-form-input 
                    id="filter-input"
                    v-model.number="numeroOT"
                    type="search"
                    autocomplete="off"
                    @keypress="isNumber($event)"
                    placeholder="Numéro de lot"
                ></b-form-input>

                <b-input-group-append>
                    <b-button squared :disabled="!numeroOT" @click="rechercheDocumentsParOT">Rechercher</b-button>
                </b-input-group-append>
              
              </b-input-group>

              <b-input-group>
                <b-form-input 
                    id="filter-input"
                    v-model="numeroFacture"
                    type="search"
                    autocomplete="off"
                    placeholder="Numéro de facture"
                ></b-form-input>

                <b-input-group-append>
                    <b-button squared :disabled="!numeroFacture" @click="rechercheDocumentsParFacture">Rechercher</b-button>
                </b-input-group-append>
              
              </b-input-group>   
              
              <b-input-group>
                <b-form-input 
                    id="filter-input"
                    v-model="refClient"
                    type="search"
                    autocomplete="off"
                    placeholder="Référence client"
                ></b-form-input>

                <b-input-group-append>
                    <b-button squared :disabled="!refClient" @click="rechercheDocumentsParRefClient">Rechercher</b-button>
                </b-input-group-append>
              
              </b-input-group>              

            </b-card-text>

          </b-card>

          <br>

          <b-card header="Recherche par date de livraison">
          
            <b-card-text>

              <b-form-group
                label="Date de début"
                label-for="datepickerDebut"
                label-cols-sm="4"
                label-cols-lg="4"
                content-cols-sm="8"
                content-cols-lg="8"                
              >

                <b-form-datepicker 
                  id="datepickerDebut" 
                  v-model="dateDeDebut"
                  :start-weekday="weekday"
                  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                ></b-form-datepicker>

              </b-form-group>

              <b-form-group
                label="Date de fin"
                label-for="datepickerFin"
                label-cols-sm="4"
                label-cols-lg="4"
                content-cols-sm="8"
                content-cols-lg="8"                 
              >

                <b-form-datepicker 
                  id="datepickerFin" 
                  v-model="dateDeFin"
                  :start-weekday="weekday"
                  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                ></b-form-datepicker>

              </b-form-group>

              <b-button squared class="bouton-recherche-par-date" :disabled="isDateFilled" @click="rechercheDocumentsParDate">Valider</b-button>

            </b-card-text>

          </b-card>

          <br>


          <br>
            
        </b-col>

        <b-col cols="8">

          <b-row class="mb-4">

          <b-col cols="3">
            <span class="messageDocumentsFound">{{nombreDeDocuments}} document(s) trouvé(s).</span>
          </b-col>

          <b-col cols="3">
            <CriteresDeRecherche @filtre="setFiltre" @parPage="setParPage" :activeNombreDeResultatParPage="false" > </CriteresDeRecherche>  
          </b-col>

          <b-col cols="4"> 
            <b-form-checkbox @change="selectionnerNonTelecharge" switch >Doc. non téléchargé</b-form-checkbox>
          </b-col>

          <b-col cols="2">
            <b-button squared @click="selectionnerToutesLesLignes" :disabled=desactiveBoutonToutSelectionner >{{isAllSelected ? 'Tout déséléctionner' : 'Tout séléctionner'}}</b-button>
          </b-col>
            
        </b-row>
      
        
          <TableauDesDocuments
            :filter=filter 
            :parPage=parPage
            :isSelectable="true"
            ref="tableauDocuments"
            @nombreDeDocuments="getNombreDeDocuments"
            @nombreDeDocumentsCoche="getNombreDeDocumentsCoche"
            :numeroOT="numeroOT"
            :ShowDownloaded="ShowDownloaded"
            :numeroFacture="numeroFacture"
            :refClient="refClient"
            :dateDeDebut="dateDeDebut"
            :dateDeFin="dateDeFin"
            @telechargementEnCours="setTelechargementEnCours"
          ></TableauDesDocuments>

          <b-row align-h="between">

            <b-col v-if="activeNombreDeResultatParPage" class="bouton-gauche-bas-tableau" cols="2">
              Résultats par page
              <b-form-group
                label-for="filter-input"
                >

                <b-form-group
                  label-for="per-page-select"
                >

                  <b-form-select
                      id="per-page-select"
                      v-model="parPage"
                      :options="pageOptions"
                  ></b-form-select>

                </b-form-group>

              </b-form-group>
            </b-col>

            <b-col cols="4">
              <span class="bouton-droite-bas-tableau" >
                <template v-if="afficheBoutonTelechargerTousLesDocuments">
                  <p><b-button squared class="" @click="telechargeTousLesDocuments" > <template v-if="telechargementEnCours"> <b-spinner small></b-spinner></template> Télécharger les documents</b-button></p>
              </template>
              </span>
            </b-col>

          </b-row>

              

              

        </b-col>

      </b-row>

    </b-container>

  </div>
</template>

<script>
  import TableauDesDocuments from '../components/TableauDocuments.vue';
  import CriteresDeRecherche from '../components/CriteresDeRechercheTableau.vue';
  import moment from 'moment';

  export default {
    components: {
      TableauDesDocuments,
      CriteresDeRecherche,
    },
    data() {
      return {
        filter:'',
        parPage:20,
        pageCourante: 1,
        nombreDeDocuments : 0,
        nombreDeDocumentsCoche : 0,
        numeroOT : '',
        numeroFacture : '',
        refClient : '',
        ShowDownloaded : true,
        isAllSelected : false,
        dateDeDebut : '',
        dateDeFin : '',
        desactiveBoutonToutSelectionner : true,
        afficheBoutonTelechargerTousLesDocuments : false,
        telechargementEnCours : false,
        pageOptions: [20, 50, 100],
        weekday: 1      
      }
    },
    
    methods: {    
      isNumber (evt) {
        let keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let keyPressed = evt.key;
    
        if (!keysAllowed.includes(keyPressed)) {
           evt.preventDefault()
        }
      },  
      selectionnerToutesLesLignes() {

        if (this.isAllSelected) {
          this.isAllSelected = false;
        } else {
          this.isAllSelected = true;
        }

        this.$refs.tableauDocuments.selectionnerToutesLesLignes();

      },
      selectionnerNonTelecharge() {

        this.ShowDownloaded = !this.ShowDownloaded
        this.$refs.tableauDocuments.selectionnerNonTelecharge();

      },      
      rechercheDocumentsParOT() {
        this.isAllSelected = false;
        this.afficheBoutonTelechargerTousLesDocuments = false;
        this.$refs.tableauDocuments.getDocumentsParOT();
      },
      rechercheDocumentsParFacture() {
        this.isAllSelected = false;
        this.afficheBoutonTelechargerTousLesDocuments = false;
        this.$refs.tableauDocuments.getDocumentsParFacture();
      },
      rechercheDocumentsParRefClient() {
        this.isAllSelected = false;
        this.afficheBoutonTelechargerTousLesDocuments = false;
        this.$refs.tableauDocuments.getDocumentsParRefClient();
      },
      rechercheDocumentsParDate() {
        this.isAllSelected = false;
        this.afficheBoutonTelechargerTousLesDocuments = false;
        this.$refs.tableauDocuments.getDocumentsParDate();
      },
      getNombreDeDocuments(value) {
        this.nombreDeDocuments = value;
      },
      getNombreDeDocumentsCoche(value) {
        this.nombreDeDocumentsCoche = value;
      },
      setFiltre(value) {
        this.filter = value;
      },
      setParPage(value) {
        this.parPage = value;
      },
      telechargeTousLesDocuments() {
        this.$refs.tableauDocuments.telechargerLesDocuments();
      },
      setTelechargementEnCours(val) {
        this.telechargementEnCours = val;
      },
      setDateDeLaSemaine() {
        var now = moment();
        var dateDebutDeLaSemaine = now.clone().weekday(1);
        var dateFinDeLaSemaine = now.clone().weekday(7);

        this.dateDeDebut = dateDebutDeLaSemaine.format('YYYY-MM-DD');
        this.dateDeFin = dateFinDeLaSemaine.format('YYYY-MM-DD');

      }
    },
    computed: {

      isDateFilled: function(){

        if ((this.dateDeDebut) && (this.dateDeFin)) {
          return false;
        } else {
          return true;
        }

      },
      
    },
    created() {
      this.setDateDeLaSemaine();
    },
    mounted() {
      this.rechercheDocumentsParDate();
      
    },
    watch: {
      nombreDeDocuments: function(val) {
        if (val == 0) {
          this.desactiveBoutonToutSelectionner = true;
        } else {
          this.desactiveBoutonToutSelectionner = false;
        }
        
      },
      nombreDeDocumentsCoche: function(val) {
        if (val > 1) {
          this.afficheBoutonTelechargerTousLesDocuments = true;
        } else {
          this.afficheBoutonTelechargerTousLesDocuments = false;
        }
      }
    }

  }

</script>

<style scoped> 

  .input-group {
    margin-bottom: 10px;
  }

  .bouton-droite-bas-tableau {
    float:right;
    margin-right: 15px;
  }

  .bouton-gauche-bas-tableau {
    margin-left:15px;
  }

  .bouton-recherche-par-date {
    float:right;
  }

  .header-tableau {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }

</style>