<template>
  <div>

    <b-table small
      striped hover 
      :items="listeTiers" 
      :fields="filtreColonnes"
      :filter="filter"
      :per-page="parPage"
      :current-page="pageCourante"
      show-empty
      empty-filtered-text="Aucune données trouvées"
      empty-text="Aucune données trouvées"
      @filtered="onFiltered"
      primary-key="number"
      selectable
      select-mode="multi"
      @row-selected="onRowSelected"
    >

    </b-table>
    
    <b-form-group
      label=""
      label-for="Pagination"
    >

      <b-pagination
        id="pagination"
        v-model="pageCourante"
        :total-rows="lignesTotales"
        :per-page="parPage"
        align="fill"
      ></b-pagination>

    </b-form-group>

  </div>
</template>


<script>

  import url from '../router/url.js';
  const axios = require('axios');

  export default {
    props: {
      'compteID' : Number,
      'isSelectable': Boolean,
      'filter': String,
      'parPage': Number,
      'typeTiers': String
    },
    data() {      
      return {
        pageCourante: 1,
        lignesTotales : 1,
        listeTiersCoche : [],
        listeTiers : [],        
        colonnes: [
          {
            label: 'ID',
            key: 'TIERS_ID',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
          {
            label: 'Société',
            key: 'TIERS_NOMSOCIETE',
            isVisible: true,
            class: 'text-center',
            tdClass: 'align-middle'
          },
        ],
      }
    },
    methods: {
      GetListeDesTiers() {                
        this.listeTiers = [];

        if (this.typeTiers == "CLIENT") {
          axios.get(url.GetTiers + this.filter).then(response => {

            if (response.data) {
                this.listeTiers = response.data;
            }
          
          }).catch(function (error) {
              alert('Erreur lors de la récupération des tiers. ' + error);
          });
        }
        else if (this.typeTiers == "EXP") {
          axios.get(url.GetTiersExp + this.filter).then(response => {

            if (response.data) {
                this.listeTiers = response.data;
            }
          
          }).catch(function (error) {
              alert('Erreur lors de la récupération des tiers. ' + error);
          });
        }

      },
      onFiltered(filteredItems) {
        this.lignesTotales = filteredItems.length;
        this.pageCourante = 1;
      },
      onRowSelected(item) {
          
        this.listeTiersCoche = [];

        for (let i = 0; i < item.length; i++) {
          this.listeTiersCoche.push({TIERS_ID: item[i].TIERS_ID, TIERS_NOMSOCIETE: item[i].TIERS_NOMSOCIETE});
        }
        
        this.$emit('GetTiersPourInsertion', this.listeTiersCoche);

      },
    },
    computed: {
      filtreColonnes() {
        return this.colonnes.filter(field => field.isVisible);
      }
    },
    mounted: function() {
      this.lignesTotales = this.listeTiers.length;
    },
    watch: {
      filter: function() {
        if (this.filter.length > 2) {
          this.GetListeDesTiers();
        } else {
          this.listeTiers = [];
        }
      }
    }

  }

</script>
